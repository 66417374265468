import * as React from "react";
import { IntlProvider } from "react-intl";

const Wrapper = ({ children, data, pageContext }) => {
  const { locale } = pageContext ?? {};
  const { translations } = data.translations ?? {};
  const array = translations?.map((item) => ({ [item.key]: item.value })) ?? [];
  const messages = Object.assign({}, ...array) ?? {};

  return (
    <IntlProvider locale={locale} defaultLocale="fr" messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default Wrapper;
